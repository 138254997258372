import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";

const CarouselCard = props => {
  const { review, name } = props.content;

  const useStyles = makeStyles(() => ({
    card: {
      borderRadius: 5,
      margin: "3% auto",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "left",
    },
    container: {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    review: {
      color: "#181A34",
      textAlign: "left",
    },
    avatar: {
      backgroundColor: "#4853BB",
      color: "white",
    },
    cardHeader: {
      paddingTop: "0",
      textAlign: "left",
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Container className={classes.container}>
        <CardContent>
          <Typography variant="subtitle1" className={classes.review}>
            {review}
          </Typography>
        </CardContent>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              {name.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={name}
          titleTypographyProps={{ variant: "h5" }}
          className={classes.cardHeader}
        ></CardHeader>
      </Container>
    </Card>
  );
};
export default CarouselCard;
