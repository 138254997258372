import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function QualificationIcon(props) {
  return (
    <SvgIcon {...props}>
      <polyline
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        points="12,20 1,20 1,1 19,1 19,8 "
        strokeLinejoin="miter"
      ></polyline>{" "}
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeMiterlimit="10"
        points="22,14.625 22,22 19,21 16,22 16,14.625 "
        strokeLinejoin="miter"
        strokeLinecap="butt"
      ></polyline>{" "}
      <circle
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        cx="19"
        cy="12"
        r="4"
        strokeLinejoin="miter"
      ></circle>{" "}
      <line
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        x1="5"
        y1="6"
        x2="14"
        y2="6"
        strokeLinejoin="miter"
      ></line>{" "}
      <line
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        x1="5"
        y1="10"
        x2="11"
        y2="10"
        strokeLinejoin="miter"
      ></line>{" "}
      <line
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        x1="5"
        y1="14"
        x2="11"
        y2="14"
        strokeLinejoin="miter"
      ></line>
    </SvgIcon>
  );
}
